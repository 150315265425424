.your-card {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
  flex-direction: column;
  min-height: 100vh;
}


.your-card-link {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    min-width: 300px;
}

.link-pic {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.your-card-contact button{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}

.social-media-btn .social-media-icon{
    font-size: 3rem;
}

.your-card-header {
    min-height: 40vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
/* btn py-0 d-flex justify-content-center align-items-center */
.your-card-header .profil-pic {
    position: absolute;
    top: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.your-card-contact .element{
    text-align: center;
    position: relative;
}
.your-card-contact .element .element-icon{
    position: absolute;
    left: 5%;
    top : 50%;
}

.youtube-box{
    border-radius: 10px;
    border: 1px solid;
    overflow: hidden;
}
.youtube-box .video{
    width: 100%;
    height: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
}

.your-card-content {
    width: 400px;
    margin-top: 75px;

}
.your-card-content .your-carte-header h2{
    font-size: 2.3rem;
}
.your-card-content .your-carte-header .bio-text{
    font-size: 1.2rem;
}

.your-card-contact i {
    font-size: 3rem;
}

.your-carte-footer {
    margin-top: auto;
    margin-top: auto;
}