.circle-picker span div {
   border-radius: 100%;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 15px;
}
.link{
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.preview-Card::-webkit-scrollbar {
   display: none;
}

.input-file {
   opacity: 0%;
   cursor: pointer;
}


.img-display-after {
   border-radius: 100%;
   width: 100%;
   height: 100%;
   overflow-clip-margin: content-box;
   overflow: clip;
   background-color: #e9e9e9;
   border: 1px solid black;
}